import React from 'react'

import PageWithLeftMenu from '../../components/PageWithLeftMenu'
import P from '../../components/P'
import SEO from '../../components/seo'

import items from './_items'

export default () => {
  return (
    <PageWithLeftMenu title="STRUCTURA ORGANIZATORICĂ" items={items}>
      <SEO
        title="Structură organizatorică"
        keywords={[`structură`, 'organizare', `fundația ebe`]}
      />
      <P>
        Lucrarea EBE este condusă de un grup indigen, dedicat echipării de
        lideri slujitori ucenicizatori, sub coordonarea unui Consiliu Director
        si a unei Echipe de Conducere și asistați de un Consiliu Consultativ
        format din misionari Entrust.
      </P>

      <P>
        <b>Consiliul Director</b>
      </P>
      <P>
        Este format dintr-un număr de 9 persoane, bărbați și femei, din diferite
        denominații evanghelice, care se află în poziții și în structuri de
        conducere în cadrul bisericilor, a confesiunilor sau departamentelor din
        care fac parte. Aceștia reprezintă interesele Fundației în denominațiile
        lor, asistă Coordonatorii Naționali și Regionali în activitatea de
        echipare, primesc rapoartele anuale de activitate ale Fundației și în
        mod specific ale fiecărui Sistem, se asigură că activitatea Fundației
        este în conformitate cu viziunea și misiunea ei și participă activ la
        dezvoltarea prin mijloacele disponibile și prin implicarea personală a
        eficientizării lucrării EBE. Consiliul Director reprezintă “garantul” că
        lucrarea decurge la parametrii de standard înalt.
      </P>

      <P>
        <b>Echipa de Conducere</b>
      </P>
      <P>
        Este formată din 7 persoane și anume: Directorul General Executiv,
        Directorul Administrativ și cei 5 Coordonatori Naționali de la cele 5
        Sisteme de lucru EBE. EBE este o resursă care asistă pastori, biserici
        si denominații evanghelice sa dezvolte Centre de Pregătire viabile, cu
        baza in biserica locală. EBE asigură cursurile din programa sa, precum
        si consultanță, prin personal specializat, încurajând bisericile și
        liderii din bisericile române sa se implice in echiparea de lideri
        slujitori. Lideri de biserici, pastori, evangheliști sau profesori la
        școli teologice, elevi si studenți, femei evlavioase, tineri cu chemare
        pentru lucrarea de slujire sau cu o inima pentru misiune, oameni care
        iubesc Cuvantul lui Dumnezeu, toți pot beneficia de pe urma acestei
        investiții care se numește:
      </P>
      <P style={{ textAlign: 'center' }}>
        <b>EBE - Educație Biblică prin Extensie</b>
      </P>
    </PageWithLeftMenu>
  )
}
